import PropTypes from "prop-types"
import React from "react"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import "typeface-inter"
import "../styles/index.css"
import Footer from "../components/Footer"
import Header from "../components/Header"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ location, children }) => {
  // Get current locale and link
  const siteLanguages = require('../data/languages')
  const url = location.pathname
  const { langs, defaultLangKey } = siteLanguages
  let langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`;
  let langsMenu = getLangs(
    langs,
    langKey,
    getUrlForLang(homeLink, url)
  )

  return (
    <>
      <Header langsMenu={langsMenu}/>
        <div>
          {children}
        </div>
      <Footer locale={langKey}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
