import Img from "gatsby-image"
import React from "react"
import Button from "../components/Button"
import TranslateCN from "../intl/cn.json"
import TranslateEN from "../intl/en.json"


const ProductCard = props => {
  const { title, productSlug, description, thumbnail, seriesSlug, locale } = props

  let buttonTitle = {}
    if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
  }

  return (
    <div className="bg-black h-auto ml-4 md:m-2 p-1 overflow-hidden group">
      <div className="h-150 lg:h-200 md:px-4 lg:px-6">
        <Img style={{height: "100%", width: "100%"}} imgStyle={{objectFit: "contain", objectPosition:"left bottom"}} fluid={thumbnail.fluid} alt={title} />
      </div>
      <div className="xl:max-w-4/5">
        <div className="mt-4 lg:pl-6 lg:pr-10 px-1">
          <h1 className="text-md md:text-lg text-white font-semibold uppercase">{title}</h1>
          <section className="my-4 text-xs md:text-sm lg:text-xs text-white text-justify" dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
        </div>
        <div className="px-1 lg:px-5">
          <Button href={`/${locale}/${seriesSlug}/${productSlug}`} color={`text-white border-white`} product="true">{buttonTitle.general.button}</Button>
        </div>
      </div>
    </div>
  )
}

export default ProductCard