import Img from "gatsby-image"
import React, {useEffect, useState} from "react"
import Swiper from "react-id-swiper"
import {isBrowser, isMobile, isMobileOnly} from "react-device-detect"

// Import Swiper styles
import "./CarouselSeries.css"
import 'swiper/css/swiper.css';
export const CarouselSeries = ({content, parentCallback, chineseTitle, englishTitle}) => {

  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
       
    let mySwiper = document.querySelector('.swiper-container-series').swiper
    mySwiper.on('slideChange', function () {
      parentCallback(mySwiper.activeIndex);
    });

    setRefresh(!refresh)

  },[])

  const swiperParams = {
    containerClass: 'swiper-container-series' ,
    mousewheel: {
      forceToAxis: true,
    },   
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    effect: "fade",
    shouldSwiperUpdate: true
  }

  
  return (
    <Swiper {...swiperParams}>
      {content.map((item,index) => {
        return (
          <div key={`slide_${index}`} >
            <Img
              fluid={item.trendImage.fluid}
              alt={item.title}
              style={{ 
                height: isMobileOnly ? `140vw` : `100%`,
                width: `100vw`
              }}
              imgStyle={{
                objectFit: isMobileOnly ? "contain" : "cover",
                objectPosition: isMobileOnly ? "0 0" : "50% 50%"
              }}
            />
            {isBrowser ? 
             <div id="series-swiper-text" className="relative z-10 bottom-4/5 max-w-3/4 no-copy">
                <svg viewBox="0 0 850 400" xmlns="http://www.w3.org/2000/svg" className="w-screen">
                  <text x="90%" y="25%" textAnchor="end" fill="rgba(0,0,0,0)" className="text-6xl text-outline absolute italic opacity-75 animated-text">{englishTitle.trend[index].title.toUpperCase()}</text>
                  <text x="90%" y="33%" textAnchor="end" fill="#ffffff" className="text-5xl italic absolute animated-text">{chineseTitle.trend[index].title}</text>
                  {item.tags.map((tag, index) => (
                      <text key={`series-trend-${index}`} x="90%" y={200+index*22} textAnchor="end" fill="#ffffff" className="centralesans-xbold text-xs animated-text">{tag}</text>
                  ))} 
                </svg>
              </div>
              :
              <div id="series-swiper-text" className="absolute top-0 bg-gradient-to-b from-transparent to-black via-black md:h-threefourths">
                <svg viewBox={isMobileOnly ? "0 0 800 600" : "0 0 800 400"} xmlns="http://www.w3.org/2000/svg" className="w-full h-auto">
                    <text x="95%" y="30%" textAnchor="end" fill="rgba(0,0,0,0)" className="text-6xl text-outline absolute italic">{englishTitle.trend[index].title.toUpperCase()}</text>
                    <text x="95%" y="32%" textAnchor="end" fill="#ffffff" className="text-5xl italic absolute ">{chineseTitle.trend[index].title}</text>
                    {item.tags.map((tag, index) => (
                        <text key={`tag-svg-${index}`} x="95%" y={isMobileOnly ? 420+index*30 : 300+index*30} textAnchor="end" fill="#ffffff" className="font-bold text-md">{tag}</text>
                    ))} 
                </svg>
                <div className="text-sm max-w-full h-260 overflow-hidden">
                    <section className="carousel-body-text my-0 mx-2 text-justify px-4 h-260 overflow-hidden" dangerouslySetInnerHTML={{ __html: item.description.childMarkdownRemark.html }} />
                </div>
              </div>

            }
          </div>
        )
      })}
    </Swiper>

  )
}

export default CarouselSeries
