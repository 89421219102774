import React from "react"
import BackgroundImage from 'gatsby-background-image'
import Button from "../Button"
import { BsChevronDoubleDown } from "react-icons/bs"
import { isMobileOnly } from "react-device-detect"
import TranslateCN from "../../intl/cn.json"
import TranslateEN from "../../intl/en.json"

const HeroProductSeries = ({content, fullpageApi, locale}) => {

  let buttonTitle = {}
  if (locale === "zh-CN") {
      buttonTitle =  TranslateCN
  } else {
      buttonTitle = TranslateEN
  }

  if(isMobileOnly){
    return (
      <BackgroundImage
        className="h-screen block md:hidden"
        fluid={content.heroBackgroundMobile.fluid}
        style={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="flex flex-col h-screen justify-end items-center">
          {/* <div className="text-center centralesans-medium">
            {content.tags.map((tag,index) => (
              <p key={`series-tag-${index}`}>{tag}</p>
            ))}
          </div> */}
          {/* <div className="my-10"> */}
            <Button onClick={() => fullpageApi.moveSectionDown()}>{buttonTitle.general.button}</Button>
          {/* </div> */}
          <div onClick={() => fullpageApi.moveSectionDown()} >
            <BsChevronDoubleDown className="text-white h-8 w-auto mt-4 mb-8"/>
          </div>
        </div>
      </BackgroundImage>
    )
  } 
  return(    
    <BackgroundImage
      className="h-screen hidden md:block"
      fluid={content.heroBackground.fluid}
      style={{
        // Defaults are overwrite-able by setting one or each of the following:
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="h-screen flex flex-col justify-center items-center md:pt-40 md:pb-40 text-white relative">
        <div className="absolute bottom-0 text-center">
          <h1 className="text-6xl centralesans-xbold">{content.title.toUpperCase()}</h1>
          <div className="md:mb-12 centralesans-medium">
            {content.tags.map((tag,index) => (
              <p key={`series-tag-${index}`}>{tag}</p>
            ))}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
  
}

export default HeroProductSeries