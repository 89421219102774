import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";
import React, {useState, useRef, useEffect, useCallback, useLayoutEffect} from "react"
import SiteMetadata from "../components/SiteMetadata"
import HeroProductSeries from "../components/ProductSeries/HeroProductSeries"
import ProductCard from "../components/ProductCard"
import gsap, {Power1} from "gsap"
import { ScrollTrigger }from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import Layout from "../layouts/Layout";
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import CarouselSeries from "../components/carousel/CarouselSeries"
import {isBrowser, MobileView, BrowserView} from "react-device-detect"
import ReactFullpage from "@fullpage/react-fullpage"
import Footer from "../components/Footer"
import Header from "../components/Header"


const ProductSeriesTemplate = ({ data, location }) => {
    // Get current locale and link
    const siteLanguages = require('../data/languages')
    const url = location.pathname
    const { langs, defaultLangKey } = siteLanguages
    let langKey = getCurrentLangKey(langs, defaultLangKey, url)
    const homeLink = `/${langKey}/`;
    let langsMenu = getLangs(
      langs,
      langKey,
      getUrlForLang(homeLink, url)
    )

    // definitions
    // set index of trend image on carousel swipe
    const [smallImgNode, setSmallImgNode] = useState(null)
    const [trendIndex, setTrendIndex] = useState(0)
    const [headerVisible, setHeaderVisible] = useState(false)
    const timeline = useRef(gsap.timeline({paused: true}))
    
    function callback(index){
        setTrendIndex(index)
    }

    const html = data.hero.trend[trendIndex].description.childMarkdownRemark.html

    let alreadyLeft = false;


    const pluginWrapper = () => {
        require ('../../static/fullpage.fadingEffect.min.js');
    };


    function onLeaveHandler(origin, destination, direction) {
        if(destination.index === 2 && !alreadyLeft && direction === "down" && destination.isLast === false ){
            alreadyLeft = true;
            getAndAnimateSize(smallImgNode, trendIndex);
            transform(styleImgSm)
            timeline.current.play()
            return false;
        } else if (origin.index === 2 && !alreadyLeft && direction === "up" && origin.isLast === false){
            alreadyLeft = true;
            getAndAnimateSize(smallImgNode, trendIndex);
            transform(styleImgSm)
            timeline.current.reverse(0)
            return false;
        }

        headerSetter(origin, destination, direction)
    }

    function headerSetter(origin, destination, direction){
        if(direction==="up"){
           setHeaderVisible(true)
          } else if(direction==="down"){
            setHeaderVisible(false)
          }
    }
    
    function afterLoadHandler(){
        alreadyLeft = false;
    }

    const filterProductsByTrend = (products, trend) => {
        const filteredProducts = []
        products.map(product => {
            if (!product.trend && product.node.trend.title === trend) {
                filteredProducts.push(product)
            } else if (product.node.trend === null ) {
                filteredProducts.push(product)
            }
            return products
        })
        return filteredProducts
    }


    // determine when image is done loading 
    const animateWhenLoadFinishesSmall = useCallback(node => {
        if (node && node.imageRef){
            node.imageRef.current.addEventListener("load", setSmallImgNode(node));
            return () => {
                node.imageRef.current.removeEventListener("load", setSmallImgNode(node));
            }  
        }      
    })


    //get transform details for gsap timeline for large-image resize to small-image and vice versa
    let styleImgSm;

    function getAndAnimateSize(node, index){    

        if (isBrowser && node && data.hero.trend.length > 1){
            let gi =  node.imageRef.current,
            // get width and height of container and aspect ratio of original photo
            rw = gi.width,
            rh = gi.height,
            ratio = data.hero.trend[index].trendImage.fluid.aspectRatio,
            h, w;
            
            // calculate the resized image height and widths based on cover
            h = rh;
            w = rh * ratio

            let width = rw + "px";
            let height = rh + "px";

            styleImgSm = {
                height: height,
                width: width,
                ease: Power1.easeOut,
                zIndex: 0,
                duration: 1
            };

            return styleImgSm

        } else {
        }
    }

    // transform with gsap
    function transform(styleParams){
        timeline.current
            .fromTo(".animated-text", {autoAlpha: 1}, {autoAlpha: 0})
            .set([".small-image", ".image-desc", ".image-svg", "#product-cards"], {autoAlpha: 0})
            .set(".large-image", {autoAlpha: 1, width: "100vw", height:"100vh"})
            .to(".large-image", styleParams)
            .to(".image-text-container", {autoAlpha: 1})
            .to(["#product-cards", ".image-desc"], {autoAlpha: 1})
            .to(".image-svg", {autoAlpha: 1})
    }



    useLayoutEffect(() => {
        
        if (smallImgNode && data.hero.trend.length > 1 && isBrowser){
                window.addEventListener('resize', () => getAndAnimateSize(smallImgNode, trendIndex));
            }  

        return() => {
            window.removeEventListener('resize', () => getAndAnimateSize(smallImgNode, trendIndex));
        }

    }, [smallImgNode, trendIndex])

    useEffect(()=>{
        // show nav bar when scroll up, hide when scroll down, fix for pages with fullpagejs
        const header = document.querySelector('.head');
        if (headerVisible){
        header.classList.remove('hidden')
        // gsap.to(".head", {autoAlpha: 1})
        } else {
        header.classList.add('hidden')
        // gsap.to(".head", {autoAlpha: 0})
        }
    },[headerVisible])


    

    return (
        <>
        <BrowserView>
            <Header path={location.pathname} locale={langKey} langsMenu={langsMenu}/>
            <SiteMetadata title="Zippo" description="Zippo China" />
            {typeof window !== 'undefined' && ( 
                <ReactFullpage
                    licenseKey={'6246B3C1-F1D84F4C-BE4DB156-9B313FEC'} 
                    navigation={false}
                    animateAnchor={false}
                    sectionSelector={".section"}
                    pluginWrapper={pluginWrapper}
                    bigSectionsDestination={"top"}
                    normalScrollElements={"#product-cards"}
                    scrollHorizontally={false}
                    fadingEffectKey= {'emlwcG8uY29tLmNuX1EwMlptRmthVzVuUldabVpXTjBmUUE='}
                    fadingEffect= {true}
                    onLeave={onLeaveHandler}
                    afterLoad={afterLoadHandler}
                    scrollOverflow={true}
                    touchSensitivity={20}
                    scrollingSpeed={200}


                    render={({ fullpageApi }) => (
                        <ReactFullpage.Wrapper>
                            <div className="section">
                                <HeroProductSeries locale={langKey} content={data.hero} fullpage={fullpageApi}/>
                            </div>
                            {(data.hero.trend.length > 1) ?
                                <div className="section">
                                    <div id="carousel-series" className="h-screen w-screen absolute top-0 z-30" >
                                        <CarouselSeries englishTitle={data.englishTitle} chineseTitle={data.chineseTitle} content={data.hero.trend} parentCallback={callback}/>
                                    </div>
                                </div>
                            :
                            <></>
                            }
                            
                            <div className="section fp-auto-height-responsive">
                                <div id="products-series" className="relative">
                                    {(data.hero.trend.length > 1) ? <Img className="hidden md:block relative h-screen w-screen large-image z-20" fluid={data.hero.trend[trendIndex].trendImage.fluid} objectFit="cover" objectPosition="50% 50%" loading="eager" /> : <></>}
                                    <div className={`vertical-align-start flex flex-col md:flex-row h-screen w-screen z-0 top-0 ${(data.hero.trend.length > 1) ? "absolute" : "relative"}`}>
                                        <div className="md:w-1/2 small-image-container absolute">
                                            <div className="relative w-full">
                                                <Img className="hidden md:block h-screen small-image" fluid={data.hero.trend[trendIndex].trendImage.fluid} objectFit="cover" objectPosition="0 0" loading="eager" ref={animateWhenLoadFinishesSmall}  />
                                                <div className="absolute top-0">
                                                    <div className="no-copy relative h-screen bg-gradient-to-b from-transparent to-black image-text-container">
                                                        <svg viewBox="0 0 800 400" xmlns="http://www.w3.org/2000/svg" className="w-full h-threefourths image-svg">
                                                            <text x="95%" y="45%" textAnchor="end" fill="rgba(0,0,0,0)" className="text-6quarterxl text-outline absolute italic">{data.englishTitle.trend[trendIndex].title.toUpperCase()}</text>
                                                            <text x="95%" y="52%" textAnchor="end" fill="#ffffff" className="text-5xl italic absolute ">{data.chineseTitle.trend[trendIndex].title}</text>
                                                            {data.hero.trend[trendIndex].tags.map((tag, index) => (
                                                                <text key={`tag-svg-${index}`} x="95%" y={290+index*30} textAnchor="end" fill="#ffffff" className="font-bold text-md">{tag}</text>
                                                            ))} 
                                                        </svg>
                                                        <div className="text-xs max-w-full image-desc h-auto">
                                                            <section className=" my-0 mr-2 ml-12 text-justify pl-40 pr-4" dangerouslySetInnerHTML={{ __html: html }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="w-screen h-screen overflow-y-auto z-40 pt-24">
                                            <div id="product-cards" className="w-full md:pr-6/100 md:pl-4/100 md:w-1/2 float-right h-auto overflow-y-hidden overflow-x-hidden mx-auto my-0 grid grid-cols-2 auto-rows-max md:auto-rows-min gap-10 m-5 py-8 bg-black z-10">
                                                {filterProductsByTrend(data.products.edges, data.hero.trend[trendIndex].title).map((product, index)=> 
                                                    <ProductCard
                                                        key={`product-card-${index}`}
                                                        title={product.node.title} 
                                                        productSlug={product.node.slug} 
                                                        description={product.node.description} 
                                                        thumbnail={product.node.images[0]}
                                                        seriesSlug={product.node.series.slug}
                                                        locale={langKey}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section fp-auto-height-responsive">
                                <Footer locale={langKey} /> 
                            </div>
                            </ReactFullpage.Wrapper>
                    )}
                />
            )}
        </BrowserView>
        <MobileView>
        <Header path={location.pathname} locale={langKey} langsMenu={langsMenu} />
        <SiteMetadata title="Zippo" description="Zippo China" />
        {typeof window !== 'undefined' && ( 
            <ReactFullpage
                licenseKey={'6246B3C1-F1D84F4C-BE4DB156-9B313FEC'} 
                navigation={false}
                animateAnchor={false}
                sectionSelector={".section"}
                pluginWrapper={pluginWrapper}
                bigSectionsDestination={"top"}
                // normalScrollElements={"#product-cards"}
                onLeave={headerSetter}
                scrollHorizontally={false}
                fadingEffectKey= {'emlwcG8uY29tLmNuX1EwMlptRmthVzVuUldabVpXTjBmUUE='}
                fadingEffect= {true}
                touchSensitivity={20}
                scrollingSpeed={200}
                scrollOverflow={true}


                render={({ fullpageApi })  => (
                    <ReactFullpage.Wrapper>
                        <div className="section">
                            <HeroProductSeries locale={langKey} content={data.hero} fullpageApi={fullpageApi}/>
                        </div>
                        <div id="series-container" className="section flex flex-col h-auto w-screen relative overflow-y-hidden overflow-x-hidden">
                            <div id="carousel-series" className="h-auto w-screen top-0 z-30" >
                                <CarouselSeries englishTitle={data.englishTitle} chineseTitle={data.chineseTitle} content={data.hero.trend} parentCallback={callback} />
                            </div>
                            <div id="product-cards" className="w-full pt-8 md:pt-16 h-auto px-2 my-0 grid grid-cols-2 auto-rows-max md:auto-rows-min gap-10 md:p-6 py-8 bg-black z-10">
                                {filterProductsByTrend(data.products.edges, data.hero.trend[trendIndex].title).map((product, index)=> 
                                    <ProductCard
                                        key={`product-card-${index}`}
                                        title={product.node.title} 
                                        productSlug={product.node.slug} 
                                        description={product.node.description} 
                                        thumbnail={product.node.images[0]}
                                        seriesSlug={product.node.series.slug}
                                        locale={langKey}
                                    />
                                )}
                            </div>
                            <div>
                                <Footer locale={langKey} /> 
                            </div>
                        </div>
  
                    </ReactFullpage.Wrapper>
                    )}
                />
            )}
        </MobileView>
        </>
    )
}





export default ProductSeriesTemplate

export const query = graphql`
  query ProductSeriesBySlug($slug: String, $locale: String) {
    hero: contentfulProductSeries(slug: {eq: $slug}, node_locale: {eq: $locale}) {
        title
        node_locale
        tags
        heroBackground {
            fluid(quality: 70, maxWidth: 1920) {
                ...GatsbyContentfulFluid_withWebp
            }
        }
        heroBackgroundMobile {
            fluid(quality: 70, maxWidth: 1080) {
                ...GatsbyContentfulFluid_withWebp
            }
        }
        trend {
            title
            trendImage {
                fluid(quality: 70, maxWidth: 1920) {
                    ...GatsbyContentfulFluid_withWebp
                    aspectRatio
                }
            }
            tags
            description {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
    chineseTitle: contentfulProductSeries(slug: {eq: $slug}, node_locale: {eq: "zh-CN"}) {
        trend {
            title
        }
    }
    englishTitle: contentfulProductSeries(slug: {eq: $slug}, node_locale: {eq: "en-US"}) {
        trend {
            title
        }
    }
    products: allContentfulProduct(filter: {node_locale: {eq: $locale}, series: {slug: {eq: $slug}}}, sort: {fields: slug, order: ASC}) {
        edges {
            node {
                title
                slug
                trend {
                    title
                    node_locale
                }
                series {
                    title
                    slug
                }
                description {
                    childMarkdownRemark {
                        html
                    }
                }
                images {
                    fluid(quality: 90, maxHeight: 300) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }
        }
    }
  }
`



